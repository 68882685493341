var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.isPop)?_c('div',{staticClass:"form-heading"},[_vm._v(_vm._s(_vm.$t('Email')))]):_vm._e(),_c('base-input',{attrs:{"id":"email","type":"email","name":"email","autocomplete":"email","placeholder":_vm.$t('Email') + ' *',"validations":[{
      condition: _vm.$v.formData.email.$error && !_vm.$v.formData.email.required
    },{
      condition: _vm.formData.disposable
    },{
      condition: _vm.errorMessage
    }]},on:{"blur":function($event){return _vm.$v.formData.email.$touch()}},model:{value:(_vm.formData.email),callback:function ($$v) {_vm.$set(_vm.formData, "email", $$v)},expression:"formData.email"}}),_c('transition',{attrs:{"name":"toggle-slide"}},[(_vm.$v.formData.email.$error || _vm.errorMessage || (_vm.errorNextStep || _vm.$v.formData.email.$error))?_c('ValidationMessages',{attrs:{"validations":[{
          condition: _vm.errorNextStep || _vm.$v.formData.email.$error,
          text: _vm.$t('Please fill in the required fields')
        },{
          condition: _vm.$v.formData.email.$error,
          text: _vm.$t('Wrong email format.'),
        },{
          condition: _vm.errorMessage,
          text: _vm.$t(_vm.errorMessage),
        }],"id":"email"}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }