<template>
  <div class="loader">
    <div class="dots"></div>
  </div>
</template>

<script>
export default {
  name: 'Loader'
}
</script>

<style lang="scss" scoped>
.loader {
  position: absolute;
  top: 0;
  left: 50%;
  margin: 8rem auto;
  transform: translateX(-50%);
  transition: all 0.2s ease-in-out;

  &.-hide {
    opacity: 0;
    pointer-events: none;
  }

  .dots {
    position: relative;
    width: .6rem;
    height: .6rem;
    border: .3rem solid #d0d5db;
    border-radius: 100%;
    animation: bouncing 1.6s ease-in-out infinite;
    transform: translateY(-50%);

    &:before {
      display: block;
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: .6rem;
      height: .6rem;
      background: #d0d5db;
      border-radius: 100%;
      transform: translate(-50%, -50%);
      animation: separateLeft 1.6s ease-in-out infinite;
    }
    &:after {
      display: block;
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: .6rem;
      height: .6rem;
      background: #d0d5db;
      border-radius: 100%;
      transform: translate(-50%, -50%);
      animation: separateRight 1.6s ease-in-out infinite;
    }
  }
}

@keyframes bouncing {
  0%, 50% {
    width: .6rem;
    height: .6rem;
  }
  20%, 30% {
    width: 2rem;
    height: 2rem;
  }
}
@keyframes separateLeft {
  0%, 50%, 100% {
    transform: translate(-50%, -50%);
  }
  1%, 49% {
    opacity: 0;
  }
  70%, 80% {
    opacity: 1;
    transform: translate(-2.1rem, -50%);
  }
}
@keyframes separateRight {
  0%, 50%, 100% {
    transform: translate(-50%, -50%);
  }
  1%, 49% {
    opacity: 0;
  }
  70%, 80% {
    opacity: 1;
    transform: translate(1.5rem, -50%);
  }
}
</style>
