<template>
  <div>
    <div class="form-heading">{{ $t('Personal data management condition') }}</div>
    <p class="form-text">{{ $t('To continue, you must agree to the following: ') }}</p>
    <div class="boxed">
      <label v-if="!isPop" class="-small" for="conditions" v-html="$t('I have read and accept the legal framework and the limitations associated with sharing personal data')">*</label>
      <Toggle v-model="formData.conditions" id="conditions" :required="true"></Toggle>
      <label v-if="isPop" class="-small" for="conditions" v-html="$t('I have read and accept the legal framework and the limitations associated with sharing personal data')">*</label>
    </div>
    <transition name="toggle-slide">
      <ValidationMessages v-if="errorNextStep && !formData.conditions"
        :validations="[{
            condition: errorNextStep && !formData.conditions,
            text: $t('Field is required.')
        }]" id="conditions" />
    </transition>
    <div class="boxed" v-if="formData.countryObject && formData.countryObject.hasMarketing">
      <label v-if="!isPop" class="-small" for="conditions" v-html="$t('I would like to receive news and promotional offers from Somfy')"></label>
      <Toggle v-model="formData.marketing" id="marketing"></Toggle>
      <label v-if="isPop" class="-small" for="conditions" v-html="$t('I would like to receive news and promotional offers from Somfy')"></label>
    </div>
    <a :href="$t('optin url')" target="_blank">{{ $t('Read in detail') }}</a>
  </div>
</template>

<script>
import Toggle from '../form/Toggle'
import ValidationMessages from '../form/ValidationMessages.vue'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'Step-5',
  props: ['formData', 'errorNextStep', 'isPop'],
  components: {
    Toggle,
    ValidationMessages
  },
  data() {
    return {
    }
  },
  validations: {
    formData: {
      conditions: { required }
    }
  },
  watch: {
    'formData.conditions' () {
      this.$emit('nextStepAuthorized', this.formData.conditions)
    }
  },
  mounted () {
    this.$emit('nextStepAuthorized', this.formData.conditions)
  }
}
</script>

<style lang="scss" scoped>

#app.-pop {
  .boxed {
    display: flex;
    width: 100%;
    align-items: center;
    margin-bottom: 1.6rem;

    label {
      font-size: em(16);
      margin-bottom: 0;
      margin-left: 1.6rem;
      line-height:  normal;
      color: var(--dark-slate-grey);
    }

    .toggle {
      margin-left: auto;
    }
  }

  a {
    padding: 0;
    border: none;
    color: #016B65;
    background: linear-gradient(0deg, #016B65, #016B65) no-repeat left bottom/100% 0.0625rem;
    transition: all 200ms ease-in-out, background-position 0s;

    &:hover, &:focus {
      color: #016B65;
      background-size: 0 rem(1);
      background-position-x: right;
    }
  }
}

#app:not(.-pop) {
  .boxed {
    display: flex;
    width: 100%;
    background: #ffffff;
    padding: 1.8rem 1.9rem;
    align-items: center;

    label {
      font-size: em(14);
      margin-bottom: 0;
      margin-right: 1.6rem;
      line-height:  normal;
      color: var(--dark-slate-grey);
    }

    .toggle {
      margin-left: auto;
    }
  }

  a {
    display: block;
    text-align: center;
    font-size: em(13);
    text-decoration: underline;
    color: var(--medium-grey);
  }
}
</style>
