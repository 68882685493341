<template>
  <div class="toggle">
    <input type="checkbox" :checked="value" @change="$emit('input', $event.target.checked)" :id="id" :required="required ? true : false" :aria-required="required ? true : false">
    <div class="switch" :class="{ '-active': value }" @click="$emit('input', !value)"></div>
  </div>
</template>

<script>
export default {
  name: 'Toggle',
  props: {
    id: {
      type: String,
      default: ''
    },
    value: {
      type: Boolean,
      default: false
    },
    onLabel: {
      type: String,
      default: 'On'
    },
    offLabel: {
      type: String,
      default: 'Off'
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
#app.-pop {
  .toggle {
    position: relative;
    display: inline-block;

    .switch {
      z-index: 10;
      position: relative;
      height: 2.4rem;
      width: 4.6rem;
      padding: 0;
      border: none;
      border-radius: 1.5rem;
      background: var(--beige);
      box-shadow: 0 0 0 1px rgba(#E3DBD1, 0.5);
      text-align: center;
      cursor: pointer;
      transition: all 0.2s ease-in-out;

      &:before {
        display: block;
        content: '';
        position: absolute;
        top: .3rem;
        left: .3rem;
        width: 1.8rem;
        height: 1.8rem;
        background: var(--white);
        border-radius: 100%;
        transition: all 0.2s ease-in-out;
        box-shadow: 2px 0 2px rgba(0, 0, 0, 0.15);
      }

      &.-active {
        background: var(--yellow-somfy);
        box-shadow: none;

        &:before {
          left: calc(100% - 2.1rem);
          background: var(--white);
        }
      }
    }

    input {
      position: absolute;
      z-index: 5;
      top: 50%;
      left: 50%;
      width: 1.2rem;
      height: 1.2rem;
      padding: 0;
      transform: translate(-50%, -50%);
      transition: all 0.2s ease-in-out;
    }
  }
}

#app:not(.-pop) {
  .toggle {
    position: relative;
    display: inline-block;

    .switch {
      z-index: 10;
      position: relative;
      height: 3rem;
      width: 5.1rem;
      padding: 0;
      border: none;
      border-radius: 1.5rem;
      background: var(--white);
      text-align: center;
      cursor: pointer;
      transition: all 0.2s ease-in-out;
      box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.3);

      &:before {
        display: block;
        content: '';
        position: absolute;
        top: .1rem;
        left: .1rem;
        width: 2.8rem;
        height: 2.8rem;
        background: var(--white);
        border-radius: 100%;
        transition: all 0.2s ease-in-out;
        box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.1), 0 3px 3px 0 rgba(0, 0, 0, 0.05), 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 1px 0 rgba(0, 0, 0, 0.05);
      }

      &.-active {
        background: var(--yellow-somfy);
        box-shadow: none;
        &:before {
          left: calc(100% - 2.9rem);
          background: var(--white);
        }
      }
    }

    input {
      position: absolute;
      z-index: 5;
      top: 50%;
      left: 50%;
      width: 1.2rem;
      height: 1.2rem;
      padding: 0;
      transform: translate(-50%, -50%);
      transition: all 0.2s ease-in-out;
    }
  }
}
</style>
