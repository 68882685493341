<template>
  <header :class="{'-show': step < 6}">
    <template v-if="isPop">
      <div class="container">
        <button type="button" class="link -arrow -start" @click="$emit('previous')" :disabled="step === 1">{{ $t('Previous') }}</button>
        <div class="progress" :class="'-step-' + step"></div>
        <transition-group name="fade" mode="out-in" tag="div" class="title">
          <span v-if="step === 1" :key="'step-1'">{{ $t('Enter the SIREN number of your company ') }}</span>
          <span v-if="step === 2 && !formData.prefill" :key="'step-2'">{{ $t('Fill in the details of your company') }}</span>
          <span v-if="step === 2 && formData.prefill" :key="'step-2'">{{ $t('Check the details of your company') }}</span>
          <span v-if="step === 3" :key="'step-3'">{{ $t('Enter your email') }}</span>
          <span v-if="step === 4" :key="'step-4'">{{ $t('Fill in your information') }}</span>
          <span v-if="step === 5" :key="'step-5'">{{ $t('Let\'s agree together') }}</span>
        </transition-group>
      </div>
    </template>
    <template v-else>
      <div class="progress" :class="'-step-' + step"></div>
      <transition-group name="fade" mode="out-in" tag="div" class="title">
        <span v-if="step === 1" :key="'step-1'">{{ $t('Enter the SIREN number of your company ') }}</span>
        <span v-if="step === 2 && !formData.prefill" :key="'step-2'">{{ $t('Fill in the details of your company') }}</span>
        <span v-if="step === 2 && formData.prefill" :key="'step-2'">{{ $t('Check the details of your company') }}</span>
        <span v-if="step === 3" :key="'step-3'">{{ $t('Enter your email') }}</span>
        <span v-if="step === 4" :key="'step-4'">{{ $t('Fill in your information') }}</span>
        <span v-if="step === 5" :key="'step-5'">{{ $t('Let\'s agree together') }}</span>
      </transition-group>
    </template>
  </header>
</template>

<script>
export default {
  props: ['step', 'formData', 'isPop'],
  name: 'Header'
}
</script>

<style lang="scss" scoped>

#app.-pop {

  header {
    position: relative;
    z-index: 100;
    top: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1.6rem 0 2.4rem;
    background: white;
    text-align: start;
    transform: translateY(-100%);
    transition: all 0.2s ease-in-out;

    &.-embed {
      position: relative;
    }

    &.-withoutPadding {
      padding-top: 0;
      justify-content: flex-start;
      height: 8rem;
    }

    &.-show {
      transform: translateY(0);
    }

    div {
      position: relative;
      text-align: start;
      width: 100%;

      span {
        display: block;
        width: 100%;
      }
    }
  }

  .link {
    margin-bottom: 1.6rem;

    &[disabled] {
      opacity: 0;
      pointer-events: none;
    }
  }

  .progress {
    display: none;
    position: relative;
    width: calc(100% - 4rem);
    max-width: 32rem;
    height: .4rem;
    margin: 0 0 2rem;
    border-radius: .2rem;
    background-color: var(--lighter-grey);

    &:after {
      position: absolute;
      left: 0;
      top: 0;
      content: '';
      display: block;
      height: .4rem;
      border-radius: .2rem;
      background-color: var(--yellow-somfy);
      transition: all 0.2s ease-in-out;
    }

    &.-step-1:after {
      width: 20%;
    }
    &.-step-2:after {
      width: 40%;
    }
    &.-step-3:after {
      width: 60%;
    }
    &.-step-4:after {
      width: 80%;
    }
    &.-step-5:after {
      width: 100%;
    }
    &.-step-6:after {
      width: 100%;
    }
  }

  .title {
    color: var(--dark-slate-grey);
    font-size: 2.4rem;
    font-weight: 500;
    line-height: 3.2rem;
  }
}

#app:not(.-pop) {
  header {
    position: fixed;
    z-index: 100;
    top: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 12rem;
    padding: 2rem 0;
    background: white;
    text-align: center;
    border-bottom: 1px solid var(--light-grey);
    transform: translateY(-100%);
    transition: all 0.2s ease-in-out;

    &.-embed {
      position: relative;
    }

    &.-withoutPadding {
      padding-top: 0;
      justify-content: flex-start;
      height: 8rem;
    }

    &.-show {
      transform: translateY(0);
    }

    div {
      position: relative;
      text-align: center;
      width: 100%;

      span {
        display: block;
        width: 100%;
      }
    }
  }
  .progress {
    position: relative;
    width: calc(100% - 4rem);
    max-width: 32rem;
    height: .4rem;
    margin: 0 0 2rem;
    border-radius: .2rem;
    background-color: var(--lighter-grey);

    &:after {
      position: absolute;
      left: 0;
      top: 0;
      content: '';
      display: block;
      height: .4rem;
      border-radius: .2rem;
      background-color: var(--yellow-somfy);
      transition: all 0.2s ease-in-out;
    }

    &.-step-1:after {
      width: 20%;
    }
    &.-step-2:after {
      width: 40%;
    }
    &.-step-3:after {
      width: 60%;
    }
    &.-step-4:after {
      width: 80%;
    }
    &.-step-5:after {
      width: 100%;
    }
    &.-step-6:after {
      width: 100%;
    }
  }

  .title {
    color: var(--medium-grey);
    padding-left: 10px;
    padding-right: 10px;
  }
}

</style>
