<template>
  <div>
    <div v-if="!isPop" class="form-heading">{{ $t('Email') }}</div>
    <base-input
      id="email"
      type="email"
      name="email"
      autocomplete="email"
      v-model="formData.email"
      @blur="$v.formData.email.$touch()"
      :placeholder="$t('Email') + ' *'"
      :validations="[{
        condition: $v.formData.email.$error && !$v.formData.email.required
      },{
        condition: formData.disposable
      },{
        condition: errorMessage
      }]"
    />
    <transition name="toggle-slide">
      <ValidationMessages v-if="$v.formData.email.$error || errorMessage || (errorNextStep || $v.formData.email.$error)"
         :validations="[{
            condition: errorNextStep || $v.formData.email.$error,
            text: $t('Please fill in the required fields')
          },{
            condition: $v.formData.email.$error,
            text: $t('Wrong email format.'),
          },{
            condition: errorMessage,
            text: $t(errorMessage),
          }]" id="email" />
    </transition>
  </div>
</template>

<script>
import BaseInput from '../form/BaseInput'
import ValidationMessages from '../form/ValidationMessages.vue'
import { required, email } from 'vuelidate/lib/validators'

export default {
  name: 'Step-3',
  props: ['formData', 'errorMessage', 'errorNextStep', 'isPop'],
  components: {
    BaseInput,
    ValidationMessages
  },
  data() {
    return {

    }
  },
  validations: {
    formData: {
      email: { required, email }
    }
  },
  watch: {
    'errorNextStep' () {
      this.$v.$touch()
    },
    '$v.$invalid' () {
      this.$v.$touch()
      this.$emit('nextStepAuthorized', !this.$v.$invalid)
    }
  },
  mounted () {
    this.$emit('nextStepAuthorized', !this.$v.$invalid)
  }
}
</script>

<style lang="scss" scoped>

</style>
