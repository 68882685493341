<template>
  <div>
    <template v-if="emailAnswer === 'validated'">
      <div class="icon">
        <svg class="valid" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 32 20" xml:space="preserve">
          <path transform="translate(16 22)" d="M12.6-21.4c0.8-0.8,2-0.8,2.8,0c0.8,0.8,0.8,2,0,2.8l-16,16c-0.7,0.7-1.9,0.8-2.7,0.1l-12-10c-0.9-0.7-1-2-0.3-2.8c0.7-0.8,2-1,2.8-0.3l10.6,8.8L12.6-21.4z" />
        </svg>
      </div>
      <div class="title">{{ $t('Request accepted') }}</div>
      <p>{{ $t('You have accepted the account creation request for ') + this.emailAccount }}</p>
    </template>
    <template v-else-if="emailAnswer === 'rejected'">
      <div class="icon">
        <svg class="pending" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 32 20" xml:space="preserve">
          <path transform="translate(16 22)" d="M12.6-21.4c0.8-0.8,2-0.8,2.8,0c0.8,0.8,0.8,2,0,2.8l-16,16c-0.7,0.7-1.9,0.8-2.7,0.1l-12-10c-0.9-0.7-1-2-0.3-2.8c0.7-0.8,2-1,2.8-0.3l10.6,8.8L12.6-21.4z" />
        </svg>
      </div>
      <div class="title">{{ $t('Request refused') }}</div>
      <p>{{ $t('You have refused the account creation request for ') + this.emailAccount }}</p>
    </template>
    <template v-else>
      <div class="icon">
        <svg class="error" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 35.9 32.9" xml:space="preserve">
        <path transform="translate(-169.000000, -215.000000) translate(152.000000, 197.000000)" d="M36.2,21.8c-0.1-0.2-0.3-0.4-0.6-0.6c-0.7-0.4-1.6-0.1-2,0.6L20.2,45.7c-0.1,0.2-0.2,0.5-0.2,0.7c0,0.8,0.7,1.5,1.5,1.5h26.9c0.3,0,0.5-0.1,0.7-0.2c0.7-0.4,1-1.3,0.6-2L36.2,21.8L36.2,21.8z M37.1,18.6c0.7,0.4,1.3,1,1.7,1.7l13.4,23.9c1.2,2.2,0.4,4.9-1.7,6.1c-0.7,0.4-1.4,0.6-2.2,0.6H21.5c-2.5,0-4.5-2-4.5-4.5c0-0.8,0.2-1.5,0.6-2.2L31,20.3C32.2,18.1,35,17.4,37.1,18.6z M35.9,41.9h-2c-0.3,0-0.5,0.2-0.5,0.5v2c0,0.3,0.2,0.5,0.5,0.5h2c0.3,0,0.5-0.2,0.5-0.5v-2C36.4,42.2,36.2,41.9,35.9,41.9z M35.9,28.5h-2c-0.3,0-0.5,0.2-0.5,0.5v9.5c0,0.3,0.2,0.5,0.5,0.5h2c0.3,0,0.5-0.2,0.5-0.5V29C36.4,28.7,36.2,28.5,35.9,28.5z"/>
      </svg>
      </div>
      <div class="title">{{ $t('Request already processed') }}</div>
      <p>{{ $t('An error has occurred, no response from the server.') }}</p>
    </template>
  </div>
</template>

<script>

export default {
  name: 'Step-6',
  props: ['emailAnswer', 'emailAccount'],
  data () {
    return {}
  }
}
</script>

<style lang="scss" scoped>

svg {
  width: 3.6rem;
  path {
    fill-rule:evenodd;
    clip-rule:evenodd;
  }
  &.valid {
    path {
      fill: var(--green);
    }
  }
  &.pending {
    path {
      fill: var(--yellow-somfy);
    }
  }
  &.error {
    path {
      fill: var(--salmon);
    }
  }
}
p {
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  text-align: center;
  color: var(--medium-grey);
}


#app.-pop {

  .icon {
    display: none;
  }

  .title {
    font-size: em(32);
    font-weight: 500;
    line-height: 4rem;
    letter-spacing: normal;
    text-align: center;
    color: var(--dark-slate-grey);
    margin-bottom: 1.4rem;

  }
}

#app:not(.-pop) {
  .icon {
    width: 7.2rem;
    height: 7.2rem;
    margin: 0 auto 1.6rem;
    background: #ffffff;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 4px 0 rgba(39, 56, 78, 0.2);
  }

  .title {
    font-size: em(36);
    font-weight: 300;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: var(--dark-slate-grey);
    margin-bottom: 1.6rem;

    @include bp-up(xl) {
      font-size: em(24);
    }
  }
}
</style>
