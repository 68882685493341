<template>
  <div>
    <template v-if="formData.type === 'main'">
      <div class="icon">
        <svg class="valid" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 32 20" xml:space="preserve">
          <path transform="translate(16 22)" d="M12.6-21.4c0.8-0.8,2-0.8,2.8,0c0.8,0.8,0.8,2,0,2.8l-16,16c-0.7,0.7-1.9,0.8-2.7,0.1l-12-10c-0.9-0.7-1-2-0.3-2.8c0.7-0.8,2-1,2.8-0.3l10.6,8.8L12.6-21.4z" />
        </svg>
      </div>
      <div class="title">{{ $t('Congratulation') }}</div>
      <p>{{ $t('Your Somfy Pro account has been successfully created.') }}</p>
    </template>
    <template v-else>
      <div class="icon">
        <svg class="pending" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 36 36" xml:space="preserve">
          <path transform="translate(-168.000000, -182.000000) translate(30.500000, 164.000000) translate(120.500000, 0.000000)" d="M35,21c-8.3,0-15,6.7-15,15s6.7,15,15,15s15-6.7,15-15S43.3,21,35,21z M35,18c9.9,0,18,8.1,18,18s-8.1,18-18,18s-18-8.1-18-18S25.1,18,35,18z M35,25.5c-0.8,0-1.5,0.7-1.5,1.5v10.5H41c0.8,0,1.5-0.7,1.5-1.5s-0.7-1.5-1.5-1.5h-4.5V27C36.5,26.2,35.8,25.5,35,25.5z"/>
        </svg>
      </div>
      <div class="title">{{ $t('Waiting for validation') }}</div>
      <p>{{ $t('Your Somfy Pro account is being validated by your company. You will receive a confirmation email and sms once it is created.') }}</p>
    </template>
  </div>
</template>

<script>

export default {
  name: 'Step-6',
  props: ['formData'],
  data () {
    return {}
  }
}
</script>

<style lang="scss" scoped>

svg {
  width: 3.6rem;
  path {
    fill-rule:evenodd;
    clip-rule:evenodd;
  }
  &.valid {
    path {
      fill: var(--green);
    }
  }
  &.pending {
    path {
      fill: var(--yellow-somfy);
    }
  }
  &.error {
    path {
      fill: var(--salmon);
    }
  }
}
p {
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  text-align: center;
  color: var(--medium-grey);
}

#app.-pop {

  .icon {
    display: none;
  }

  .title {
    font-size: em(32);
    font-weight: 500;
    line-height: 4rem;
    letter-spacing: normal;
    text-align: center;
    color: var(--dark-slate-grey);
    margin-bottom: 1.4rem;

  }
}

#app:not(.-pop) {
  .icon {
    width: 7.2rem;
    height: 7.2rem;
    margin: 0 auto 1.6rem;
    background: #ffffff;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 4px 0 rgba(39, 56, 78, 0.2);
  }

  .title {
    font-size: em(36);
    font-weight: 300;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: var(--dark-slate-grey);
    margin-bottom: 1.6rem;

    @include bp-up(xl) {
      font-size: em(24);
    }
  }
}
</style>
