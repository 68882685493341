var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('base-select',{attrs:{"id":"country","name":"country","options":_vm.countries,"selected":_vm.formData.country,"placeholder":_vm.$t('Country') + ' *',"autocomplete":"country","validations":[{
      condition: _vm.$v.formData.country.$error && !_vm.$v.formData.country.required
    }]},model:{value:(_vm.formData.country),callback:function ($$v) {_vm.$set(_vm.formData, "country", $$v)},expression:"formData.country"}}),_c('transition',{attrs:{"name":"toggle-slide"}},[(_vm.activeType && _vm.legaltype[_vm.activeType].length > 1 && (_vm.formData.country !== 'US' && _vm.formData.country !== 'CA'))?_c('base-select',{attrs:{"id":"legal_type","name":"legal_type","options":_vm.legaltype[_vm.activeType],"selected":_vm.formData.legal_type,"placeholder":_vm.$t(_vm.countries.find(function (c) { return c.value === _vm.activeType; }).legaltypeLabel) + ' *',"validations":[{
        condition: _vm.$v.formData.legal_type.$error && !_vm.$v.formData.legal_type.required
      }]},model:{value:(_vm.formData.legal_type),callback:function ($$v) {_vm.$set(_vm.formData, "legal_type", $$v)},expression:"formData.legal_type"}}):_vm._e()],1),_c('transition',{attrs:{"name":"toggle-slide"}},[(_vm.formData.country !== 'US' && _vm.formData.country !== 'CA')?_c('base-input',{ref:"legal_id",attrs:{"id":"legal_id","type":"text","name":"legal_id","placeholder":_vm.$t('ID') + ' *',"validations":[{
        condition: _vm.$v.formData.legal_id.$error && !_vm.$v.formData.legal_id.required
      },
      {
        condition: !_vm.$v.formData.legal_id.reg && _vm.$v.formData.legal_id.$error
      },
      {
        condition: !_vm.formData.legal_exists
      },
      {
        condition: !_vm.formData.authorized_activity
      }]},on:{"keyup":function($event){_vm.formData.legal_exists = true}},model:{value:(_vm.formData.legal_id),callback:function ($$v) {_vm.$set(_vm.formData, "legal_id", $$v)},expression:"formData.legal_id"}}):_vm._e()],1),_c('transition',{attrs:{"name":"toggle-slide"}},[(!_vm.formData.authorized_activity || !_vm.formData.legal_exists || !_vm.$v.formData.legal_id.reg || (_vm.errorNextStep || _vm.$v.formData.country.$error || _vm.$v.formData.legal_type.$error || _vm.$v.formData.legal_id.$error))?_c('ValidationMessages',{attrs:{"validations":[{
        condition: _vm.errorNextStep || _vm.$v.formData.country.$error || _vm.$v.formData.legal_type.$error || _vm.$v.formData.legal_id.$error,
        text: _vm.$t('Please fill in the required fields')
      },
      {
        condition: !_vm.formData.legal_exists,
        text: _vm.$t('ID not found'),
      },
      {
        condition: !_vm.formData.authorized_activity,
        text: _vm.$t('Account creation is not possible for this activity'),
      },
      {
        condition: !_vm.$v.formData.legal_id.reg,
        text: _vm.$t('ID format is incorrect'),
      }],"id":"legal_id"}}):_vm._e()],1),_c('div',{staticClass:"require"},[_vm._v(_vm._s(_vm.$t('* Required')))])],1)}
var staticRenderFns = []

export { render, staticRenderFns }