<template>
  <div>
    <div class="boxed">
      <base-input
        v-if="!formData.customer_exists_sf && false"
        id="customer"
        type="text"
        name="customer"
        v-model="formData.customer"
        :placeholder="$t('Somfy customer number')"
        :validations="[{
          condition: !formData.customer_exists_sf && (!formData.customer_exists_somfy && formData.customer)
        }]"
      />
      <base-input
        id="account_name"
        type="text"
        name="account_name"
        :disabled="formData.prefill"
        v-model="formData.account_name"
        autocomplete="organization"
        :placeholder="$t('Company name') + ' *'"
        :validations="[{
          condition: $v.formData.account_name.$error && !$v.formData.account_name.required
        }]"
      />
      <base-input
        v-if="!formData.customer_exists_sf || (formData.customer_exists_sf && formData.street)"
        id="street"
        type="text"
        name="street"
        :disabled="formData.prefill"
        v-model="formData.street"
        autocomplete="street-address"
        :placeholder="$t('Address') + ' *'"
        :validations="[{
          condition: $v.formData.street.$error && !$v.formData.street.required
        }]"
      />
      <base-input
        v-if="formData.countryObject && formData.countryObject.hasZipcode"
        id="zipcode"
        type="text"
        name="zipcode"
        :disabled="formData.prefill"
        v-model="formData.zipcode"
        autocomplete="postal-code"
        :placeholder="$t('Postal code') + ' *'"
        :validations="[{
          condition: $v.formData.zipcode.$error && !$v.formData.zipcode.required
        }, {
          condition: $v.formData.zipcode.$error && !$v.formData.zipcode.validZipcode
        }]"
      />
      <base-input
        v-if="!formData.customer_exists_sf || formData.country === 'US' || formData.country === 'CA' || (formData.customer_exists_sf && formData.city)"
        id="city"
        type="text"
        name="city"
        :disabled="formData.prefill"
        v-model="formData.city"
        autocomplete="address-level2"
        :placeholder="$t('City') + ' *'"
        :validations="[{
          condition: $v.formData.city.$error && !$v.formData.city.required
        }]"
      />
    </div>
    <transition name="toggle-slide">
      <ValidationMessages v-if="(!formData.customer_exists_sf && !formData.customer_exists_somfy && formData.customer) || (errorNextStep || $v.formData.account_name.$error || $v.formData.street.$error || $v.formData.zipcode.$error || $v.formData.city.$error)"
        :validations="[{
          condition: errorNextStep || $v.formData.account_name.$error || $v.formData.street.$error || $v.formData.zipcode.$error || $v.formData.city.$error,
          text: $t('Please fill in the required fields')
        },
        {
          condition: !formData.customer_exists_sf && !formData.customer_exists_somfy && formData.customer,
          text: $t('Invalid customer ID'),
        },
        {
          condition: !$v.formData.zipcode.validZipcode && $v.formData.zipcode.$error,
          text: $t('Incorrect zipcode format'),
        },
        ]" id="legal_id" />
    </transition>
    <div class="require">{{ $t('* Required') }}</div>
  </div>
</template>

<script>
import BaseInput from '../form/BaseInput'
import { required, requiredIf } from 'vuelidate/lib/validators'
import ValidationMessages from '../form/ValidationMessages'
import { validZipcode } from '@/components/steps/Step-1'

export default {
  name: 'Step-2',
  props: ['formData', 'errorNextStep'],
  components: {
    BaseInput,
    ValidationMessages
  },
  data() {
    return {

    }
  },
  validations: {
    formData: {
      account_name: { required },
      street: {
        required: requiredIf(function () {
          return !this.formData.customer_exists_sf || (this.formData.customer_exists_sf && this.formData.street)
        })
      },
      zipcode: {
        required: requiredIf(function () {
          return this.formData.countryObject && this.formData.countryObject.hasZipcode
        }),
        reg: v => new RegExp(validZipcode).test(v)
      },      
      city: {
        required: requiredIf(function () {
          return !this.formData.customer_exists_sf || (this.formData.customer_exists_sf && this.formData.city)
        })
      },
    }
  },
  watch: {
    'errorNextStep' () {
      this.$v.$touch()
    },
    '$v.$invalid' () {
      this.$v.$touch()
      this.$emit('nextStepAuthorized', !this.$v.$invalid)
    }
  },
  mounted () {
    this.$emit('nextStepAuthorized', !this.$v.$invalid)
  }
}
</script>

<style lang="scss" scoped>

</style>
