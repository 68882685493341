<template>
  <div>
    <div class="boxed">
      <base-input
        id="firstname"
        type="text"
        name="firstname"
        autocomplete="given-name"
        v-model="formData.firstname"
        :placeholder="$t('First name') + ' *'"
        :validations="[{
          condition: $v.formData.firstname.$error && !$v.formData.firstname.required
        }]"
      />
      <base-input
        v-if="formData.countryObject && formData.countryObject.hasMiddlename"
        id="middlename"
        type="text"
        name="middlename"
        autocomplete="additional-name"
        v-model="formData.middlename"
        :placeholder="$t('Middle name') + ' *'"
        :validations="[{
          condition: $v.formData.middlename.$error && !$v.formData.middlename.required
        }]"
      />
      <base-input
        id="lastname"
        type="text"
        name="lastname"
        autocomplete="family-name"
        v-model="formData.lastname"
        :placeholder="$t('Last name') + ' *'"
        :validations="[{
          condition: $v.formData.lastname.$error && !$v.formData.lastname.required
        }]"
      />
      <base-input
        id="mobile"
        type="mobile"
        name="mobile"
        autocomplete="tel"
        v-model="formData.mobile"
        :placeholder="$t('Mobile phone') + ' *'"
        :validations="[{
          condition: $v.formData.mobile.$error && !$v.formData.mobile.required
        }, {
          condition: $v.formData.mobile.$error && !$v.formData.mobile.validMobile
        }]"
      />
      <base-select
        v-if="formData.countryObject && formData.countryObject.languages && formData.countryObject.languages.length > 1"
        :selected="formData.countryObject && formData.countryObject.languages && formData.countryObject.languages.length === 1 ? formData.countryObject.languages[0].value : null"
        id="language"
        name="language"
        :options="formData.countryObject.languages"
        :placeholder="$t('Language') + ' *'"
        v-model="formData.language"
        autocomplete="language"
        :validations="[{
          condition: $v.formData.language.$error && !$v.formData.language.required
        }]"
      />
      <base-select
        v-if="formData.countryObject && formData.countryObject.hasDepartment"
        :selected="formData.department"
        id="department"
        name="department"
        :options="departmentList"
        :placeholder="$t('Department') + ' *'"
        v-model="formData.department"
      />
    </div>
    <transition name="toggle-slide">
      <ValidationMessages v-if="errorNextStep && $v.formData.mobile.$error"
          :validations="[{
            condition: errorNextStep && ($v.formData.mobile.$error && $v.formData.firstname.$error && $v.formData.lastname.$error),
            text: $t('Please fill in the required fields')
          },
          {
            condition: !$v.formData.mobile.validMobile && $v.formData.mobile.$error,
            text: $t('Incorrect mobile number'),
          },
        ]" id="mobile" />
    </transition>

    <div v-if="formData.sso_exists">
      <label class="form-heading" v-html="$t('Your email is already used for a Somfy account that will thus be upgraded. <br/><br/>Your credentials will stay the same.')"></label>
    </div>
    <div v-else>
      <base-input
        v-if="isPop"
        id="password"
        type="password"
        name="password"
        autocomplete="off"
        v-model="formData.password"
        :required="true"
        @blur="$v.formData.password.$touch()"
        @keyup="$v.formData.password.$touch()"
        :placeholder="$t('Password') + ' *'"
        :validations="[{
            condition: !$v.formData.password.required && $v.formData.password.$error
          },
          {
            condition: !$v.formData.password.validPwd && $v.formData.password.$error
          },
          {
            condition: !$v.formData.password.minLength && $v.formData.password.$error
          }
        ]"
      />
      <base-input
        v-else
        id="password"
        type="password"
        name="password"
        autocomplete="off"
        v-model="formData.password"
        :required="true"
        @blur="$v.formData.password.$touch()"
        @keyup="$v.formData.password.$touch()"
        :placeholder="$t('Password') + ' *'"
      />
      <transition name="toggle-slide">
        <ValidationMessages v-if="$v.formData.password.$error || formData.password"
          :validations="[
            {
              condition: !$v.formData.password.required && $v.formData.password.$error,
              text: $t('Field is required.')
            },
            {
              condition: !$v.formData.password.validPwd && $v.formData.password.$error,
              text: $t('Password must have Lower Case, Upper Case, Digits and Special Characters.'),
            },
            {
              condition: !$v.formData.password.minLength && $v.formData.password.$error,
              text: $t('Password must be at least 8 characters.'),
            },
          ]" id="password" />
      </transition>
      <base-input
        v-if="isPop"
        id="confirmation"
        type="password"
        name="confirmation"
        autocomplete="off"
        v-model="formData.confirmation"
        :required="true"
        @blur="$v.formData.confirmation.$touch()"
        @keyup="$v.formData.confirmation.$touch()"
        :placeholder="$t('Confirmation') + ' *'"
        :validations="[
          {
              condition: !$v.formData.confirmation.required && $v.formData.confirmation.$error
          },
          {
            condition: !$v.formData.confirmation.sameAsPassword && $v.formData.confirmation.$error,
          }
        ]"
      />
      <base-input
        v-else
        id="confirmation"
        type="password"
        name="confirmation"
        autocomplete="off"
        v-model="formData.confirmation"
        :required="true"
        @blur="$v.formData.confirmation.$touch()"
        @keyup="$v.formData.confirmation.$touch()"
        :placeholder="$t('Confirmation') + ' *'"
      />
      <transition name="toggle-slide">
        <ValidationMessages v-if="$v.formData.confirmation.$error || formData.confirmation"
          :validations="[
            {
                condition: !$v.formData.confirmation.required && $v.formData.confirmation.$error,
                text: $t('Field is required.')
            },
            {
              condition: !$v.formData.confirmation.sameAsPassword && $v.formData.confirmation.$error,
              text: $t('Passwords does not match.'),
            },
          ]" id="confirmation" />
      </transition>
    </div>
  </div>
</template>

<script>
import BaseInput from '../form/BaseInput'
import BaseSelect from '../form/BaseSelect'
import ValidationMessages from '../form/ValidationMessages.vue'
//eslint-disable-next-line
export const validPwd = helpers.regex('validPwd', /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W\_])[A-Za-z\d\W\_]{8,}$/)
import { validMobile } from '@/components/steps/Step-1'
import { helpers, minLength, required, sameAs, requiredIf } from 'vuelidate/lib/validators'

export default {
  name: 'Step-4',
  props: ['formData', 'errorNextStep', 'isPop'],
  components: {
    BaseInput,
    BaseSelect,
    ValidationMessages
  },
  data() {
    return {
      departmentList: [
        {label: this.$t('Accounting'), value: 'Accounting'},
        {label: this.$t('Customer Service'), value: 'Customer Service'},
        {label: this.$t('Engineering'), value: 'Engineering'},
        {label: this.$t('Field Service'), value: 'Field Service'},
        {label: this.$t('Human Resource'), value: 'Human Resource'},
        {label: this.$t('KAM / Brand mgt. Department'), value: 'KAM / Brand mgt. Department'},
        {label: this.$t('Management'), value: 'Management'},
        {label: this.$t('Marketing'), value: 'Marketing'},
        {label: this.$t('Operations'), value: 'Operations'},
        {label: this.$t('Production'), value: 'Production'},
        {label: this.$t('Purchasing'), value: 'Purchasing'},
        {label: this.$t('Sales'), value: 'Sales'},
        {label: this.$t('Warehouse'), value: 'Warehouse'},
        {label: this.$t('Quality'), value: 'Quality'}
      ]
    }
  },
  validations: {
    formData: {
      mobile: {
        required,
        reg: v => new RegExp(validMobile).test(v)
      },
      firstname: {
        required
      },
      middlename: {
        required: requiredIf(function () {
          return this.formData.countryObject && this.formData.countryObject.hasMiddlename
        })
      },
      lastname: {
        required
      },
      password: {
        minLength: minLength(8),
        validPwd,
        required: requiredIf(function () {
          return ! (this.formData.sso_exists)
        })
      },
      confirmation: {
        required: requiredIf(function () {
          return ! (this.formData.sso_exists)
        }),
        sameAsPassword: sameAs('password'),
      },
      language: {
        required: requiredIf(function () {
          return this.formData.countryObject && this.formData.countryObject.languages && this.formData.countryObject.languages.length > 1
        })
      }
    }
  },
  watch: {
    'errorNextStep' () {
      this.$v.$touch()
    },
    '$v.$invalid' () {
      this.$v.$touch()
      this.$emit('nextStepAuthorized', !this.$v.$invalid)
    }
  },
  mounted () {
    this.$emit('nextStepAuthorized', !this.$v.$invalid)
  }
}
</script>

<style lang="scss" scoped>

</style>
