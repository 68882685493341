<template>
  <div class="form-line" :class="{'-top': $attrs.value || inputFocus || autofill, '-error': validations.find( o => o.condition )}">
    <label :for="id">{{ placeholder }}</label>
    <div>
      <select
        :id="id"
        :name="name"
        :autocomplete="autocomplete"
        @focus="focus()"
        @blur="blur()"
        :required="required ? true : false"
        :aria-required="required ? true : false"
        :aria-invalid="validations.find( o => o.condition ) ? true : false"
        :aria-describedby="validations.find( o => o.condition ) ? 'error-' + id : false"
        @input="$emit('input', $event.target.value)"
        @change="$emit('input', $event.target.value)"
      >
        <option disabled selected value v-if="!selected" />
        <option
          v-for="(option, key) in options"
          :key="key"
          :value="option.value"
          :disabled="option.disabled"
          v-bind="{selected: option.value === selected}"
        >
          {{ $t(option.label) }}
        </option>
      </select>
      <div class="input">
        <template v-if="input">{{ input }}</template>
        <span v-else>{{ $t('Select') }}</span>
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" :class="{'-bottom': input}">
          <path d="M14.293 4.293c.39-.39 1.024-.39 1.414 0 .39.39.39 1.024 0 1.414l-6 6c-.39.39-1.024.39-1.414 0l-6-6c-.39-.39-.39-1.024 0-1.414.39-.39 1.024-.39 1.414 0L9 9.586l5.293-5.293z" transform="translate(-321.000000, -204.000000) translate(16.000000, 180.000000) translate(305.000000, 24.000000) translate(9.000000, 8.000000) scale(-1, 1) rotate(90.000000) translate(-9.000000, -8.000000)"/>
        </svg>
      </div>
      <i aria-hidden="true" v-if="!isPop">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
          <path d="M8.5 11.965c.276 0 .5.224.5.5v1c0 .276-.224.5-.5.5h-1c-.276 0-.5-.224-.5-.5v-1c0-.276.224-.5.5-.5h1zM8.5 3c.276 0 .5.224.5.5v6c0 .276-.224.5-.5.5h-1c-.276 0-.5-.224-.5-.5v-6c0-.276.224-.5.5-.5h1z" transform="translate(-321.000000, -460.000000) translate(16.000000, 436.000000) translate(305.000000, 24.000000)"/>
        </svg>
      </i>
    </div>
  </div>
</template>

<script>

export default {
  name: 'BaseSelect',
  data() {
    return {
      isPop: false,
      autofill: false,
      inputFocus: false
    }
  },
  computed: {
    input() {
      let option = this.options.find(o => (o.value.toString() === this.$attrs.value))
      return option ? this.$t(option.label) : null
    }
  },
  methods: {
    focus() {
      this.inputFocus = true
      this.$emit('focus')
    },
    blur() {
      this.inputFocus = false
      this.$emit('blur')
    }
  },
  props: {
    id: {
      type: String,
      required: false,
      default: ''
    },
    name: {
      type: String,
      required: false,
      default: ''
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    options: {
      type: Array,
      required: true,
      default: () => []
    },
    selected: {
      type: String,
      required: false,
      default: ''
    },
    placeholder: {
      type: String,
      required: false,
      default: ''
    },
    autocomplete: {
      type: String,
      required: false,
      default: ''
    },
    validations: {
      type: Array,
      default: () => []
    }
  },
  mounted() {
    const urlParams = new URLSearchParams(window.location.search)
    this.isPop = urlParams.get('pop') === '1'

    try {
      if (this.$el.querySelector('select:-webkit-autofill')) {
        this.autofill = true
      }
    } catch (e) {
      console.log(e)
    }
  }
}
</script>
