<template>
  <footer :class="'-step-' + step">
    <div class="container">
      <template v-if="isPop">
        <g-recaptcha v-if="step < 6" :data-btn-class="'btn -primary'" :data-sitekey="recaptchaKey" :data-validate="validate" :data-callback="callback">
          {{ $t('Next') }}
        </g-recaptcha> <!-- :disabled="!activeNextStep" -->
        <a href="#finish-main" @click="postMessage" v-else-if="step === 6 && formData.type === 'main'" class="btn -primary">{{ $t('Finish') }}</a>
        <a href="#finish-sub" @click="postMessage" v-else-if="step === 6" class="btn -primary">{{ $t('Finish') }}</a>
        <a href="#existing" v-else-if="step === 'email'" class="btn -primary">{{ $t('Finish') }}</a>
        <button href="#retry" v-else type="button" @click="$emit('retry')" class="btn -secondary">{{ $t('Try again') }}</button>
      </template>
      <template v-else>
        <template v-if="step < 6">
          <button type="button" class="btn" @click="$emit('previous')" :disabled="step === 1">{{ $t('Previous') }}</button>
          <g-recaptcha :data-btn-class="'btn -yellow'" :data-sitekey="recaptchaKey" :data-validate="validate" :data-callback="callback">
            {{ $t('Next') }}
          </g-recaptcha> <!-- :disabled="!activeNextStep" -->
        </template>
        <a href="#finish-main" v-else-if="step === 6 && formData.type === 'main'" class="btn -yellow">{{ $t('Finish') }}</a>
        <a href="#finish-sub" v-else-if="step === 6" class="btn -yellow">{{ $t('Finish') }}</a>
        <a href="#existing" v-else-if="step === 'email'" class="btn -yellow">{{ $t('Finish') }}</a>
        <button href="#retry" v-else type="button" @click="$emit('retry')" class="btn -yellow">{{ $t('Try again') }}</button>
      </template>
    </div>
  </footer>
</template>

<script>
import gRecaptcha from '@finpo/vue2-recaptcha-invisible';

export default {
  props: ['step', 'activeNextStep', 'formData', 'isPop', 'recaptchaKey'],
  components: {
    gRecaptcha,
  },
  name: 'Footer',
  methods: {
    validate() {
      return true
    },
    callback(token) {
      // google recaptcha token , then you can pass to backend with your form data .
      if (token) {
        this.$emit('next', token)
      } else {
        // if you use data-size show reCAPTCHA , maybe you will get empty token.
        alert('please check you are not robot');
      }
    },
    postMessage() {
      if (this.formData.type === 'main') {
        window.parent.postMessage('accountSuccessfullyCreated', '*')
      } else {
        window.parent.postMessage('accountWaitingForValidation', '*')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
#app.-pop {
  footer {
    z-index: 100;
    padding: 0 0 3.2rem;
    transition: all 0.2s ease-in-out;

    .btn {
      width: 100%;
    }

    &.-embed {
      position: relative;
    }

    &.-hide {
      opacity: 0;
      pointer-events: none;
    }

    .container {
      width: 100%;
      transition: all 0.4s ease-in-out;
    }
  }
}

#app:not(.-pop) {
  footer {
    position: fixed;
    z-index: 100;
    bottom: 0;
    width: 100%;
    padding: 5.6rem 2rem;
    transition: all 0.2s ease-in-out;

    &.-embed {
      position: relative;
    }

    &.-hide {
      opacity: 0;
      pointer-events: none;
    }

    @include bp-down(md) {
      margin-top: 4rem;
      padding: 1.6rem 2rem;
      box-shadow: 0 -4px 12px 0 var(--dark-slate-grey-15);
      background-color: white;

      .btn, & > div {
        width: 100%;
      }
    }

    .container {
      width: 100%;
      height: 4.8rem;
      transition: all 0.4s ease-in-out;

      @include bp-down(md) {
        height: 11.2rem;
      }

      & > .btn:first-child {
        position: absolute;
        left: 2rem;
        z-index: 10;

        @include bp-down(md) {
          left: 0;
          bottom: 0;
        }
      }

      & > div:last-child {
        position: absolute;
        right: 2rem;
        z-index: 10;

        @include bp-down(md) {
          right: 0;
          top: 0;
          width: 100%;

          ::v-deep button {
            width: 100%;
          }
        }
      }
    }

    &.-step-1 {
      .container {
        max-width: 19rem;

        @include bp-down(md) {
          max-width: none;
          max-height: 4.8rem;
        }

        & > .btn:first-child {
          opacity: 0;
          z-index: 5;
          pointer-events: none;
        }
      }
    }

    &.-step-6,
    &.-step-email,
    &.-step-error {
      .container {

        @include bp-down(md) {
          height: 4.8rem;
        }
      }
    }
  }
}
</style>
