var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"boxed"},[_c('base-input',{attrs:{"id":"firstname","type":"text","name":"firstname","autocomplete":"given-name","placeholder":_vm.$t('First name') + ' *',"validations":[{
        condition: _vm.$v.formData.firstname.$error && !_vm.$v.formData.firstname.required
      }]},model:{value:(_vm.formData.firstname),callback:function ($$v) {_vm.$set(_vm.formData, "firstname", $$v)},expression:"formData.firstname"}}),(_vm.formData.countryObject && _vm.formData.countryObject.hasMiddlename)?_c('base-input',{attrs:{"id":"middlename","type":"text","name":"middlename","autocomplete":"additional-name","placeholder":_vm.$t('Middle name') + ' *',"validations":[{
        condition: _vm.$v.formData.middlename.$error && !_vm.$v.formData.middlename.required
      }]},model:{value:(_vm.formData.middlename),callback:function ($$v) {_vm.$set(_vm.formData, "middlename", $$v)},expression:"formData.middlename"}}):_vm._e(),_c('base-input',{attrs:{"id":"lastname","type":"text","name":"lastname","autocomplete":"family-name","placeholder":_vm.$t('Last name') + ' *',"validations":[{
        condition: _vm.$v.formData.lastname.$error && !_vm.$v.formData.lastname.required
      }]},model:{value:(_vm.formData.lastname),callback:function ($$v) {_vm.$set(_vm.formData, "lastname", $$v)},expression:"formData.lastname"}}),_c('base-input',{attrs:{"id":"mobile","type":"mobile","name":"mobile","autocomplete":"tel","placeholder":_vm.$t('Mobile phone') + ' *',"validations":[{
        condition: _vm.$v.formData.mobile.$error && !_vm.$v.formData.mobile.required
      }, {
        condition: _vm.$v.formData.mobile.$error && !_vm.$v.formData.mobile.validMobile
      }]},model:{value:(_vm.formData.mobile),callback:function ($$v) {_vm.$set(_vm.formData, "mobile", $$v)},expression:"formData.mobile"}}),(_vm.formData.countryObject && _vm.formData.countryObject.languages && _vm.formData.countryObject.languages.length > 1)?_c('base-select',{attrs:{"selected":_vm.formData.countryObject && _vm.formData.countryObject.languages && _vm.formData.countryObject.languages.length === 1 ? _vm.formData.countryObject.languages[0].value : null,"id":"language","name":"language","options":_vm.formData.countryObject.languages,"placeholder":_vm.$t('Language') + ' *',"autocomplete":"language","validations":[{
        condition: _vm.$v.formData.language.$error && !_vm.$v.formData.language.required
      }]},model:{value:(_vm.formData.language),callback:function ($$v) {_vm.$set(_vm.formData, "language", $$v)},expression:"formData.language"}}):_vm._e(),(_vm.formData.countryObject && _vm.formData.countryObject.hasDepartment)?_c('base-select',{attrs:{"selected":_vm.formData.department,"id":"department","name":"department","options":_vm.departmentList,"placeholder":_vm.$t('Department') + ' *'},model:{value:(_vm.formData.department),callback:function ($$v) {_vm.$set(_vm.formData, "department", $$v)},expression:"formData.department"}}):_vm._e()],1),_c('transition',{attrs:{"name":"toggle-slide"}},[(_vm.errorNextStep && _vm.$v.formData.mobile.$error)?_c('ValidationMessages',{attrs:{"validations":[{
          condition: _vm.errorNextStep && (_vm.$v.formData.mobile.$error && _vm.$v.formData.firstname.$error && _vm.$v.formData.lastname.$error),
          text: _vm.$t('Please fill in the required fields')
        },
        {
          condition: !_vm.$v.formData.mobile.validMobile && _vm.$v.formData.mobile.$error,
          text: _vm.$t('Incorrect mobile number'),
        } ],"id":"mobile"}}):_vm._e()],1),(_vm.formData.sso_exists)?_c('div',[_c('label',{staticClass:"form-heading",domProps:{"innerHTML":_vm._s(_vm.$t('Your email is already used for a Somfy account that will thus be upgraded. <br/><br/>Your credentials will stay the same.'))}})]):_c('div',[(_vm.isPop)?_c('base-input',{attrs:{"id":"password","type":"password","name":"password","autocomplete":"off","required":true,"placeholder":_vm.$t('Password') + ' *',"validations":[{
          condition: !_vm.$v.formData.password.required && _vm.$v.formData.password.$error
        },
        {
          condition: !_vm.$v.formData.password.validPwd && _vm.$v.formData.password.$error
        },
        {
          condition: !_vm.$v.formData.password.minLength && _vm.$v.formData.password.$error
        }
      ]},on:{"blur":function($event){return _vm.$v.formData.password.$touch()},"keyup":function($event){return _vm.$v.formData.password.$touch()}},model:{value:(_vm.formData.password),callback:function ($$v) {_vm.$set(_vm.formData, "password", $$v)},expression:"formData.password"}}):_c('base-input',{attrs:{"id":"password","type":"password","name":"password","autocomplete":"off","required":true,"placeholder":_vm.$t('Password') + ' *'},on:{"blur":function($event){return _vm.$v.formData.password.$touch()},"keyup":function($event){return _vm.$v.formData.password.$touch()}},model:{value:(_vm.formData.password),callback:function ($$v) {_vm.$set(_vm.formData, "password", $$v)},expression:"formData.password"}}),_c('transition',{attrs:{"name":"toggle-slide"}},[(_vm.$v.formData.password.$error || _vm.formData.password)?_c('ValidationMessages',{attrs:{"validations":[
          {
            condition: !_vm.$v.formData.password.required && _vm.$v.formData.password.$error,
            text: _vm.$t('Field is required.')
          },
          {
            condition: !_vm.$v.formData.password.validPwd && _vm.$v.formData.password.$error,
            text: _vm.$t('Password must have Lower Case, Upper Case, Digits and Special Characters.'),
          },
          {
            condition: !_vm.$v.formData.password.minLength && _vm.$v.formData.password.$error,
            text: _vm.$t('Password must be at least 8 characters.'),
          } ],"id":"password"}}):_vm._e()],1),(_vm.isPop)?_c('base-input',{attrs:{"id":"confirmation","type":"password","name":"confirmation","autocomplete":"off","required":true,"placeholder":_vm.$t('Confirmation') + ' *',"validations":[
        {
            condition: !_vm.$v.formData.confirmation.required && _vm.$v.formData.confirmation.$error
        },
        {
          condition: !_vm.$v.formData.confirmation.sameAsPassword && _vm.$v.formData.confirmation.$error,
        }
      ]},on:{"blur":function($event){return _vm.$v.formData.confirmation.$touch()},"keyup":function($event){return _vm.$v.formData.confirmation.$touch()}},model:{value:(_vm.formData.confirmation),callback:function ($$v) {_vm.$set(_vm.formData, "confirmation", $$v)},expression:"formData.confirmation"}}):_c('base-input',{attrs:{"id":"confirmation","type":"password","name":"confirmation","autocomplete":"off","required":true,"placeholder":_vm.$t('Confirmation') + ' *'},on:{"blur":function($event){return _vm.$v.formData.confirmation.$touch()},"keyup":function($event){return _vm.$v.formData.confirmation.$touch()}},model:{value:(_vm.formData.confirmation),callback:function ($$v) {_vm.$set(_vm.formData, "confirmation", $$v)},expression:"formData.confirmation"}}),_c('transition',{attrs:{"name":"toggle-slide"}},[(_vm.$v.formData.confirmation.$error || _vm.formData.confirmation)?_c('ValidationMessages',{attrs:{"validations":[
          {
              condition: !_vm.$v.formData.confirmation.required && _vm.$v.formData.confirmation.$error,
              text: _vm.$t('Field is required.')
          },
          {
            condition: !_vm.$v.formData.confirmation.sameAsPassword && _vm.$v.formData.confirmation.$error,
            text: _vm.$t('Passwords does not match.'),
          } ],"id":"confirmation"}}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }