<template>
  <div class="form-error" :id="'error-' + id" :aria-hidden="validations.find( o => o.condition ) ? 'false' : 'true'">
    <template v-if="isPop">
      <span v-for="(validation, index) in validations.filter( o => o.condition )" :key="index" data-testid="errorMessage" v-html="validation.text"></span>
    </template>
    <template v-else>
      <div class="error-bar" :class="{'-valid': !validations.find( o => o.condition )}"></div>
      <span v-for="(validation, index) in validations.filter( o => o.condition )" :key="index" data-testid="errorMessage" v-html="validation.text"></span>
      <span v-if="id === 'password' && !validations.find( o => o.condition )">
        {{ $t('Secure password') }}
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
          <defs>
            <filter id="tmkf3yflea" color-interpolation-filters="auto">
              <feColorMatrix in="SourceGraphic" values="0 0 0 0 0.627451 0 0 0 0 0.854902 0 0 0 0 0.768627 0 0 0 1.000000 0"/>
            </filter>
          </defs>
          <g  fill-rule="evenodd" fill="none" filter="url(#tmkf3yflea)" transform="translate(-145.000000, -472.000000) translate(16.000000, 388.000000)">
            <path fill="#A0DAC4" d="M14.293 3.293c.39-.39 1.024-.39 1.414 0 .39.39.39 1.024 0 1.414l-8 8c-.366.366-.95.392-1.347.061l-6-5c-.424-.353-.482-.984-.128-1.408.353-.424.984-.482 1.408-.128l5.299 4.415 7.354-7.354z" transform="translate(128.999975, 84.000000)"/>
          </g>
        </svg>
      </span>
      <span v-if="id === 'confirmation' && !validations.find( o => o.condition )">
        {{ $t('Identical password') }}
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
          <defs>
            <filter id="tmkf3yflea" color-interpolation-filters="auto">
              <feColorMatrix in="SourceGraphic" values="0 0 0 0 0.627451 0 0 0 0 0.854902 0 0 0 0 0.768627 0 0 0 1.000000 0"/>
            </filter>
          </defs>
          <g  fill-rule="evenodd" fill="none" filter="url(#tmkf3yflea)" transform="translate(-145.000000, -472.000000) translate(16.000000, 388.000000)">
            <path fill="#A0DAC4" d="M14.293 3.293c.39-.39 1.024-.39 1.414 0 .39.39.39 1.024 0 1.414l-8 8c-.366.366-.95.392-1.347.061l-6-5c-.424-.353-.482-.984-.128-1.408.353-.424.984-.482 1.408-.128l5.299 4.415 7.354-7.354z" transform="translate(128.999975, 84.000000)"/>
          </g>
        </svg>
      </span>
    </template>
  </div>
</template>
<script>
export default {
  props: {
    validations: {
      type: Array,
      default: () => []
    },
    id: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      isPop: false
    }
  },
  mounted() {
    const urlParams = new URLSearchParams(window.location.search)
    this.isPop = urlParams.get('pop') === '1'
  }
}
</script>
