<template>
  <div class="form-line" :class="{'-top': value || inputFocus || autofill || type === 'date', '-icon': type === 'password', '-tooltip': tooltip, '-disabled': disabled, '-error': validations.find( o => o.condition )}">
    <label :for="id">{{ placeholder }}</label>
    <div v-if="isPop">
      <input
        :id="id"
        :type="type === 'password' ? passType : type"
        :name="name"
        :autocomplete="autocomplete"
        :value="value"
        :autofocus="autofocus"
        :ref="name"
        :disabled="disabled"
        :required="required ? true : false"
        :aria-required="required ? true : false"
        :aria-invalid="validations.find( o => o.condition ) ? true : false"
        :aria-describedby="validations.find( o => o.condition ) ? 'error-' + id : false"
        @blur="blur()"
        @focus="inputFocus = true"
        @input="$emit('input', $event.target.value)"
        @keyup.enter="$emit('keyup.enter', $event.target.value)"
        @keyup="$emit('keyup', $event)"
      >
      <button
        v-if="type === 'password'"
        type="button"
        @click="togglePassType()"
        :aria-label="$t('Toggle password visibility')"
        :title="$t('Toggle password visibility')" >
        <svg v-if="icon === 'icon-view-on'" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
          <path d="M11.747 4.003c4.86-.13 8.568 2.972 10.988 6.96l.634 1.044-.824 1.334c-2.41 3.789-5.77 6.54-10.247 6.656-4.879.118-8.607-3-10.988-6.968l-.616-1.026.614-1.028c2.315-3.877 5.84-6.852 10.439-6.972zm.38 1.998l-.327.002c-4.387.114-7.324 3.69-8.593 5.7L3.025 12c1.015 1.691 4.255 6.117 9.225 5.997 4.387-.114 7.293-3.69 8.588-5.7l.187-.297c-1.026-1.691-4.278-6.13-9.225-5.997zM12 8c2.21 0 4 1.79 4 4s-1.79 4-4 4-4-1.79-4-4 1.79-4 4-4zm0 2c-1.105 0-2 .895-2 2s.895 2 2 2 2-.895 2-2-.895-2-2-2z" transform="translate(-310.000000, -408.000000) translate(16.000000, 388.000000) translate(294.000000, 20.000000)"/>
        </svg>
        <svg v-else xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
          <path d="M11.747 4.003c4.86-.13 8.568 2.972 10.988 6.96l.634 1.044-.824 1.334c-2.41 3.789-5.77 6.54-10.247 6.656-4.879.118-8.607-3-10.988-6.968l-.616-1.026.614-1.028c2.315-3.877 5.84-6.852 10.439-6.972zm.38 1.998l-.327.002c-4.387.114-7.324 3.69-8.593 5.7L3.025 12c1.015 1.691 4.255 6.117 9.225 5.997 4.387-.114 7.293-3.69 8.588-5.7l.187-.297c-1.026-1.691-4.278-6.13-9.225-5.997zM12 8c2.21 0 4 1.79 4 4s-1.79 4-4 4-4-1.79-4-4 1.79-4 4-4zm0 2c-1.105 0-2 .895-2 2s.895 2 2 2 2-.895 2-2-.895-2-2-2zM2.121 23.536l2.829-2.829c.39-.39.39-1.024 0-1.414-.39-.39-1.024-.39-1.414 0L.707 22.12c-.39.39-.39 1.024 0 1.415.39.39 1.024.39 1.414 0zM20.536 4.95l2.828-2.829c.39-.39.39-1.023 0-1.414-.39-.39-1.024-.39-1.414 0L19.12 3.536c-.39.39-.39 1.023 0 1.414.39.39 1.024.39 1.415 0z"/>
        </svg>
      </button>
      <button
        v-if="tooltip"
        type="button"
        class="info"
        @click="$emit('openTooltip', true)"
        :aria-expanded="tooltipState ? 'true' : 'false'"
        :aria-controls="'modal-' + id"
        :aria-label="placeholder + ' ' + $t('(opens tooltip)')">
        <i class="icon-info"></i>
      </button>
    </div>
    <template v-else>
      <input
          :id="id"
          :type="type === 'password' ? passType : type"
          :name="name"
          :autocomplete="autocomplete"
          :value="value"
          :autofocus="autofocus"
          :ref="name"
          :disabled="disabled"
          :required="required ? true : false"
          :aria-required="required ? true : false"
          :aria-invalid="validations.find( o => o.condition ) ? true : false"
          :aria-describedby="validations.find( o => o.condition ) ? 'error-' + id : false"
          @blur="blur()"
          @focus="inputFocus = true"
          @input="$emit('input', $event.target.value)"
          @keyup.enter="$emit('keyup.enter', $event.target.value)"
          @keyup="$emit('keyup', $event)"
      >
      <button
          v-if="type === 'password'"
          type="button"
          @click="togglePassType()"
          :aria-label="$t('Toggle password visibility')"
          :title="$t('Toggle password visibility')" >
        <svg v-if="icon === 'icon-view-on'" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
          <path d="M11.747 4.003c4.86-.13 8.568 2.972 10.988 6.96l.634 1.044-.824 1.334c-2.41 3.789-5.77 6.54-10.247 6.656-4.879.118-8.607-3-10.988-6.968l-.616-1.026.614-1.028c2.315-3.877 5.84-6.852 10.439-6.972zm.38 1.998l-.327.002c-4.387.114-7.324 3.69-8.593 5.7L3.025 12c1.015 1.691 4.255 6.117 9.225 5.997 4.387-.114 7.293-3.69 8.588-5.7l.187-.297c-1.026-1.691-4.278-6.13-9.225-5.997zM12 8c2.21 0 4 1.79 4 4s-1.79 4-4 4-4-1.79-4-4 1.79-4 4-4zm0 2c-1.105 0-2 .895-2 2s.895 2 2 2 2-.895 2-2-.895-2-2-2z" transform="translate(-310.000000, -408.000000) translate(16.000000, 388.000000) translate(294.000000, 20.000000)"/>
        </svg>
        <svg v-else xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
          <path d="M11.747 4.003c4.86-.13 8.568 2.972 10.988 6.96l.634 1.044-.824 1.334c-2.41 3.789-5.77 6.54-10.247 6.656-4.879.118-8.607-3-10.988-6.968l-.616-1.026.614-1.028c2.315-3.877 5.84-6.852 10.439-6.972zm.38 1.998l-.327.002c-4.387.114-7.324 3.69-8.593 5.7L3.025 12c1.015 1.691 4.255 6.117 9.225 5.997 4.387-.114 7.293-3.69 8.588-5.7l.187-.297c-1.026-1.691-4.278-6.13-9.225-5.997zM12 8c2.21 0 4 1.79 4 4s-1.79 4-4 4-4-1.79-4-4 1.79-4 4-4zm0 2c-1.105 0-2 .895-2 2s.895 2 2 2 2-.895 2-2-.895-2-2-2zM2.121 23.536l2.829-2.829c.39-.39.39-1.024 0-1.414-.39-.39-1.024-.39-1.414 0L.707 22.12c-.39.39-.39 1.024 0 1.415.39.39 1.024.39 1.414 0zM20.536 4.95l2.828-2.829c.39-.39.39-1.023 0-1.414-.39-.39-1.024-.39-1.414 0L19.12 3.536c-.39.39-.39 1.023 0 1.414.39.39 1.024.39 1.415 0z"/>
        </svg>
      </button>
      <button
          v-if="tooltip"
          type="button"
          class="info"
          @click="$emit('openTooltip', true)"
          :aria-expanded="tooltipState ? 'true' : 'false'"
          :aria-controls="'modal-' + id"
          :aria-label="placeholder + ' ' + $t('(opens tooltip)')">
        <i class="icon-info"></i>
      </button>
      <i aria-hidden="true">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
          <path d="M8.5 11.965c.276 0 .5.224.5.5v1c0 .276-.224.5-.5.5h-1c-.276 0-.5-.224-.5-.5v-1c0-.276.224-.5.5-.5h1zM8.5 3c.276 0 .5.224.5.5v6c0 .276-.224.5-.5.5h-1c-.276 0-.5-.224-.5-.5v-6c0-.276.224-.5.5-.5h1z" transform="translate(-321.000000, -460.000000) translate(16.000000, 436.000000) translate(305.000000, 24.000000)"/>
        </svg>
      </i>
    </template>
  </div>
</template>

<script>

export default {
  name: 'BaseInput',
  data () {
    return {
      isPop: false,
      inputFocus: false,
      passType: 'password',
      iconActive: false,
      icon: 'icon-view-on',
      autofill: false
    }
  },
  props: {
    type: {
      type: String,
      required: true
    },
    id: {
      type: String,
      required: true
    },
    value: {
      type: [String, Number],
      default: ''
    },
    name: {
      type: String,
      required: false,
      default: ''
    },
    placeholder: {
      type: String,
      required: false,
      default: ''
    },
    autocomplete: {
      type: String,
      required: false,
      default: null
    },
    focus: {
      type: Boolean,
      required: false,
      default: false
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    autofocus: {
      type: Boolean,
      required: false,
      default: false
    },
    validations: {
      type: Array,
      default: () => []
    },
    keyspace: {
      type: Boolean,
      required: false,
      default: false
    },
    tooltip: {
      type: String,
      required: false,
      default: ''
    },
    tooltipState: {
      type: Boolean,
      required: false,
      default: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  methods: {
    togglePassType () {
      if (this.passType === 'password') {
        this.passType = 'text'
        this.icon = 'icon-view-off'
      } else {
        this.passType = 'password'
        this.icon = 'icon-view-on'
      }
    },
    // setFocus sets focus on a field which has a value of 'ref' tag equal to fieldName
    setFocus (fieldName) {
      if (this.name === fieldName) {
        this.$refs[this.name].focus()
      }
    },
    blur () {
      this.inputFocus = false
      this.$emit('blur')
    }
  },
  mounted () {
    const urlParams = new URLSearchParams(window.location.search)
    this.isPop = urlParams.get('pop') === '1'

    if (this.focus) {
      this.$refs[this.name].focus()
    }

    try {
      if (this.$el.querySelector('input:-webkit-autofill')) {
        this.autofill = true
      }
    } catch (e) {
      console.log(e)
    }
  }
}
</script>
