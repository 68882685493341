var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"boxed"},[(!_vm.formData.customer_exists_sf && false)?_c('base-input',{attrs:{"id":"customer","type":"text","name":"customer","placeholder":_vm.$t('Somfy customer number'),"validations":[{
        condition: !_vm.formData.customer_exists_sf && (!_vm.formData.customer_exists_somfy && _vm.formData.customer)
      }]},model:{value:(_vm.formData.customer),callback:function ($$v) {_vm.$set(_vm.formData, "customer", $$v)},expression:"formData.customer"}}):_vm._e(),_c('base-input',{attrs:{"id":"account_name","type":"text","name":"account_name","disabled":_vm.formData.prefill,"autocomplete":"organization","placeholder":_vm.$t('Company name') + ' *',"validations":[{
        condition: _vm.$v.formData.account_name.$error && !_vm.$v.formData.account_name.required
      }]},model:{value:(_vm.formData.account_name),callback:function ($$v) {_vm.$set(_vm.formData, "account_name", $$v)},expression:"formData.account_name"}}),(!_vm.formData.customer_exists_sf || (_vm.formData.customer_exists_sf && _vm.formData.street))?_c('base-input',{attrs:{"id":"street","type":"text","name":"street","disabled":_vm.formData.prefill,"autocomplete":"street-address","placeholder":_vm.$t('Address') + ' *',"validations":[{
        condition: _vm.$v.formData.street.$error && !_vm.$v.formData.street.required
      }]},model:{value:(_vm.formData.street),callback:function ($$v) {_vm.$set(_vm.formData, "street", $$v)},expression:"formData.street"}}):_vm._e(),(_vm.formData.countryObject && _vm.formData.countryObject.hasZipcode)?_c('base-input',{attrs:{"id":"zipcode","type":"text","name":"zipcode","disabled":_vm.formData.prefill,"autocomplete":"postal-code","placeholder":_vm.$t('Postal code') + ' *',"validations":[{
        condition: _vm.$v.formData.zipcode.$error && !_vm.$v.formData.zipcode.required
      }, {
        condition: _vm.$v.formData.zipcode.$error && !_vm.$v.formData.zipcode.validZipcode
      }]},model:{value:(_vm.formData.zipcode),callback:function ($$v) {_vm.$set(_vm.formData, "zipcode", $$v)},expression:"formData.zipcode"}}):_vm._e(),(!_vm.formData.customer_exists_sf || _vm.formData.country === 'US' || _vm.formData.country === 'CA' || (_vm.formData.customer_exists_sf && _vm.formData.city))?_c('base-input',{attrs:{"id":"city","type":"text","name":"city","disabled":_vm.formData.prefill,"autocomplete":"address-level2","placeholder":_vm.$t('City') + ' *',"validations":[{
        condition: _vm.$v.formData.city.$error && !_vm.$v.formData.city.required
      }]},model:{value:(_vm.formData.city),callback:function ($$v) {_vm.$set(_vm.formData, "city", $$v)},expression:"formData.city"}}):_vm._e()],1),_c('transition',{attrs:{"name":"toggle-slide"}},[((!_vm.formData.customer_exists_sf && !_vm.formData.customer_exists_somfy && _vm.formData.customer) || (_vm.errorNextStep || _vm.$v.formData.account_name.$error || _vm.$v.formData.street.$error || _vm.$v.formData.zipcode.$error || _vm.$v.formData.city.$error))?_c('ValidationMessages',{attrs:{"validations":[{
        condition: _vm.errorNextStep || _vm.$v.formData.account_name.$error || _vm.$v.formData.street.$error || _vm.$v.formData.zipcode.$error || _vm.$v.formData.city.$error,
        text: _vm.$t('Please fill in the required fields')
      },
      {
        condition: !_vm.formData.customer_exists_sf && !_vm.formData.customer_exists_somfy && _vm.formData.customer,
        text: _vm.$t('Invalid customer ID'),
      },
      {
        condition: !_vm.$v.formData.zipcode.validZipcode && _vm.$v.formData.zipcode.$error,
        text: _vm.$t('Incorrect zipcode format'),
      } ],"id":"legal_id"}}):_vm._e()],1),_c('div',{staticClass:"require"},[_vm._v(_vm._s(_vm.$t('* Required')))])],1)}
var staticRenderFns = []

export { render, staticRenderFns }