<template>
  <div id="app" :class="{ '-pop': isPop }">
    <Header :step="step" @previous="previousStep" :formData="formData" :class="{ '-embed': isEmbed, '-withoutPadding': withoutPadding }" :isPop="isPop"></Header>
    <main :class="{ '-embed': isEmbed, '-withoutPadding': withoutPadding, '-pop': isPop }">
      <div class="container">
        <form class="form" action="#" novalidate :class="{'-hide': loading}" @submit.prevent="">
          <transition :name="anim">
            <component :is="'Step-' + step" :emailAnswer="emailAnswer" :emailAccount="emailAccount" :formData="formData" :errorMessage="errorMessage" @nextStepAuthorized="authorizedNextStep" :errorNextStep="errorNextStep" :isPop="isPop"></component>
          </transition>
        </form>
        <Loader :class="{'-hide': !loading}"></Loader>
      </div>
    </main>
    <Footer :class="{'-hide': loading, '-embed': isEmbed }" :step="step" @previous="previousStep" @next="nextStep" @retry="step = prevStep" :activeNextStep="activeNextStep" :formData="formData" :isPop="isPop" :recaptchaKey="recaptchaKey" ></Footer>
  </div>
</template>

<script>
import Header from './components/Header.vue'
import Footer from './components/Footer.vue'
import Loader from './components/Loader.vue'
import Step1 from './components/steps/Step-1.vue'
import Step2 from './components/steps/Step-2.vue'
import Step3 from './components/steps/Step-3.vue'
import Step4 from './components/steps/Step-4.vue'
import Step5 from './components/steps/Step-5.vue'
import Step6 from './components/steps/Step-6.vue'
import StepEmail from './components/steps/Step-Email.vue'
import StepError from './components/steps/Step-Error.vue'
import StepWarning from './components/steps/Step-Warning.vue'

export default {
  name: 'App',
  components: {
    Header,
    Footer,
    Loader,
    Step1,
    Step2,
    Step3,
    Step4,
    Step5,
    Step6,
    StepError,
    StepWarning,
    StepEmail
  },
  data () {
    return {
      step: 1,
      prevStep: 1,
      isPop: false,
      isEmbed: false,
      withoutPadding: false,
      bodyCustomer: {},
      bodyAccount: {},
      anim: 'slide',
      loading: false,
      activeNextStep: false,
      transaction_id: '',
      errorMessage: '',
      emailAnswer: null,
      emailAccount: null,
      errorNextStep: false,
      formData: {
        legal_id: '',
        legal_type: 'vatNo',
        type: 'sub',
        legal_exists: true,
        sso_exists: false,
        authorized_activity: true,
        customer_exists_sf: true,
        customer_exists_somfy: true,
        prefill: false,
        country: null,
        countryObject: null,
        customer: '',
        account_name: '',
        province: '',
        street: '',
        zipcode: '',
        city: '',
        email: '',
        lastname: '',
        middlename: '',
        firstname: '',
        department: '',
        mobile: '',
        password: '',
        confirmation: '',
        marketing: false,
        language: null
      },
      json: {
        esba: process.env.VUE_APP_URL_MIDDLEWARE,
        esbb: process.env.VUE_APP_URL_MIDDLEWARE,
        esbc: process.env.VUE_APP_URL_MIDDLEWARE,
        esbd: process.env.VUE_APP_URL_MIDDLEWARE,
        esbe: process.env.VUE_APP_URL_MIDDLEWARE
      },
      recaptchaKey: process.env.VUE_APP_RECAPTCHA
    }
  },
  watch: {
    '$i18n.locale' () {
      this.callTCReload()
    },
  },
  mounted () {
    const urlParams = new URLSearchParams(window.location.search)

    this.$i18n.locale = navigator.language
    if (urlParams.get('locale')) {
       this.$i18n.locale = urlParams.get('locale')
    }
    if (urlParams.get('country') === 'AT') {
        this.$i18n.locale = 'de-AT'
    } 
    this.generateToken(30)
    this.isPop = urlParams.get('pop') === '1'
    this.isEmbed = urlParams.get('embed')
    this.formData.country = urlParams.get('country')
    this.withoutPadding = urlParams.get('withoutPadding')
    if (urlParams.get('hashcode') && urlParams.get('answer')) {
      this.loading = true
      this.step = 'email'
      this.emailAnswer = urlParams.get('answer')
      this.emailAccount = urlParams.get('email')
        const axiosConfig = {
          headers: {
           "Content-Type": "application/json"
          },
          credentials: "same-origin",
          withCredentials: true
        }
      this.axios
          .get(JSON.parse(JSON.stringify(this.json['esbe'] + 'updateAccount/' + this.transaction_id + '/' + urlParams.get('hashcode') + '?answer=' + this.emailAnswer)), axiosConfig)
          .then(response => this.esbeData(response))
          .catch(error => this.errorJSON(error))
    }

    document.addEventListener("click",function(e){
      if(e.target && e.target.tagName=="A"){
          if(e.target.href.endsWith("#existing")){
            console.log("Posting redirectToLogin message to parent window")
            parent.postMessage("redirectToLogin", "*");
          }
      }
    });

  },
  methods: {
    generateToken (n) {
      let chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
      let token = ''
      for (let i = 0; i < n; i++) {
        token += chars[Math.floor(Math.random() * chars.length)]
      }
      this.transaction_id = token
    },
    previousStep () {
      this.anim = 'slide-reverse'
      
      this.step--
      this.prevStep = this.step
      this.errorNextStep = false
    },
    nextStep (captchaToken) {
      if (!this.activeNextStep) {
        this.errorNextStep = true
      } else {
        this.errorNextStep = false
        this.anim = 'slide'
        const axiosConfig = {
          headers: {
           "Content-Type": "application/json"
          },
          credentials: "same-origin",
          withCredentials: true
        }

// Fake call :              .get(JSON.parse(JSON.stringify(this.json['esba'])))
// real call :              .get(JSON.parse(JSON.stringify(this.json['esba'] + 'checkLegalID/' + this.transaction_id + '/' + this.formData.country + '/' + this.formData.legal_id + '/' + this.formData.legal_type)), axiosConfig)

        switch (this.step) {
          case 1:
            this.generateToken(30)
            if (this.formData.country === 'US' || this.formData.country === 'CA') {
              this.errorMessage = ''
              this.step = 2
              this.prevStep = 1
              this.callTCEvent('account.creation.step1-success', this.formData.legal_id)
            } else {
              this.loading = true
              this.axios.defaults.withCredentials = true
              this.axios.defaults.headers.post['Content-Type'] = "application/json"
              this.axios
                  .get(JSON.parse(JSON.stringify(this.json['esba'] + 'checkLegalID/' + this.transaction_id + '/' + this.formData.country + '/' + this.formData.legal_id + '/' + this.formData.legal_type + '?captcha='  + captchaToken)), axiosConfig)
                  .then(response => this.esbaData(response))
                  .catch(error => this.errorJSON(error))
            }
            break
          case 2:
            if (this.formData.country === 'US' || this.formData.country === 'CA') {
              this.errorMessage = ''
              this.step++
              this.prevStep = this.step
              this.callTCEvent('account.creation.step2-success')
            } else {
              this.loading = true
              this.bodyCustomer = {"account_name": this.formData.account_name, "street": this.formData.street, "zipcode": this.formData.zipcode, "city": this.formData.city, "province": this.formData.province, "customer_nb": this.formData.customer}
              this.axios
                  .post(JSON.parse(JSON.stringify(this.json['esbb'] + 'checkCustomerNumber/' + this.transaction_id + '?captcha='  + captchaToken)), JSON.stringify(this.bodyCustomer), axiosConfig)
                  .then(response => this.esbbData(response))
                  .catch(error => this.errorJSON(error))
            }
            break
          case 3:
            this.loading = true
            this.axios
                .get(JSON.parse(JSON.stringify(this.json['esbc'] + 'checkEmail/' + this.transaction_id + '/' + this.formData.email + '?country_code=' + this.formData.country + '&account_name=' + this.formData.account_name + '&captcha='  + captchaToken)), axiosConfig)
                .then(response => this.esbcData(response))
                .catch(error => this.errorJSON(error))
            break
          case 5:
            this.loading = true
            this.bodyAccount = { "email" : this.formData.email, "lastname" : this.formData.lastname, "firstname" : this.formData.firstname, "middlename" : this.formData.middlename, "department": this.formData.department, "mobile" : this.formData.mobile, "password" : this.formData.password, "language": this.formData.language, "account_name" : this.formData.account_name, "street" : this.formData.street, "zipcode" : this.formData.zipcode, "city" : this.formData.city, "province" : '', "customer_nb" : this.formData.customer, "marketing_optin" : this.formData.marketing}
            this.axios
                .post(this.json['esbd'] + 'createAccount/' + this.transaction_id + '?captcha='  + captchaToken, JSON.stringify(this.bodyAccount), axiosConfig)
                .then(response => this.esbdData(response))
                .catch(error => this.errorJSON(error))
            break
          default:
            this.step++
            this.prevStep = this.step
        }
      }
    },
    authorizedNextStep (e) {
      this.activeNextStep = e
      if (e) {
        this.errorNextStep = false
      }
    },
    esbaData (response) {
      this.loading = false
      this.errorMessage = ''

      this.formData.legal_exists = response.data.legal_exists
      if (typeof response.data.authorized_activity !== 'undefined') {
        this.formData.authorized_activity = response.data.authorized_activity
      } else {
        this.formData.authorized_activity = true;
      }
      if (response.data.legal_exists && this.formData.authorized_activity) {
        this.formData.account_name = response.data.account_name
        this.formData.street = response.data.street
        this.formData.zipcode = response.data.zipcode
        this.formData.city = response.data.city
        this.formData.province = response.data.province
        if (typeof response.data.country !== 'undefined') {
          this.formData.country = response.data.country
        }
        this.formData.legal_exists = response.data.legal_exists
        this.formData.prefill = response.data.customer_exists_sf
        this.formData.customer_exists_sf = response.data.customer_exists_sf

        setTimeout(() => {
          this.step++
          this.prevStep = this.step
          this.callTCEvent('account.creation.step1-success', this.formData.legal_id)
        }, 100)
      } else {
        this.callTCEvent('account.creation.companyID-invalid', this.formData.legal_id)
      }

    },
    esbbData (response) {
      this.loading = false
      this.errorMessage = ''

      if (this.formData.legal_exists) {
        this.formData.customer_exists_somfy = response.data.customer_exists_somfy

        setTimeout(() => {
          this.step++
          this.prevStep = this.step
          this.callTCEvent('account.creation.step2-success')
        }, 100)
      }

    },
    esbcData (response) {
      this.loading = false
      this.errorMessage = ''

      if (response.data.status === "ok") {
        this.formData.type = response.data.type
        this.formData.sso_exists = response.data.sso_exists
        setTimeout(() => {          
          this.step++
          this.prevStep = this.step
          this.callTCEvent('account.creation.step3-success')
        }, 100)
      } else {
        console.log('Error Status >>> ', response)
        // this.step = 'error'
        this.errorMessage = response.data.message
        this.callTCEvent('account.creation.SomfyID-invalid')
      }
    },
    esbdData (response) {
      this.loading = false
      this.errorMessage = ''

      if (response.data.status === "ok") {
        this.formData.type = response.data.type
        setTimeout(() => {
          this.step++
          this.prevStep = this.step
          this.callTCEvent('account.creation.step4-success')
        }, 100)
      } else {
        console.log('Error Status >>> ', response)
        // this.step = 'error'
        this.errorMessage = response.data.message
        this.callTCEvent('account.creation.email-already-exists')
      }

    },
    esbeData () {
      this.loading = false
      this.errorMessage = ''
      this.callTCEvent('account.creation.confirmation')
    },
    errorJSON (error) {
      console.log('Error Call ESB >>> ', error)
      this.loading = false
      this.callTCEvent('technical_error', this.step)
      if (error.response.status === 409) {
        console.log('ESB status 409 >>> ')
        console.log('step : '+this.step)
        this.errorMessage = error.response.data.message
        if (this.step == 5) {
          this.step = 'warning'
        }
      } else {
        this.step = 'error'
      }
      console.log('Error step final : '+this.step)
    },
    disposableEmail () {
      this.axios
          .get('https://disposable.debounce.io/?email=' + this.formData.email)
          .then(response => {
            this.formData.disposable = response.data.disposable === "true"
          })
          .catch(error => this.errorJSON(error))
    },
    callTCEvent(action, info) {
      let event = {
        evt_category: 'account_webform',
        evt_button_action: action
      }
      if (action === 'technical_error') {
        event.evt_weborm_step = info
      } else {
        if (info) {
          event.evt_siren_code = info
        }
      }

      window.tC.container.reload({
        events: {
          click: [{}, event]
        }
      });
    },
    callTCReload () {
      console.log("BEGIN callTCReload")
      let browser_country = this.$i18n.locale?.split('-')[1].toUpperCase()
      let val_country = 'United States'
      switch (browser_country) {
        case "AT": val_country = 'Austria'; break;
        case "FR": val_country = 'France'; break;
        case "DE": val_country = 'Germany'; break;
        case "IT": val_country = 'Italy'; break;
        case "PT": val_country = 'Portugal'; break;
        case "GR": val_country = 'Greece'; break;
        case "ES": val_country = 'Spain'; break;
        case "CH": val_country = 'Switzerland'; break;
        case "PL": val_country = 'Poland'; break;
        case "CZ": val_country = 'Czech Republic'; break;
        case "SK": val_country = 'Slovakia'; break;
        case "HU": val_country = 'Hungary'; break;
        case "RO": val_country = 'Romania'; break;
        case "HR": val_country = 'Croatia'; break;
        case "RS": val_country = 'Serbia'; break;
        case "LT": val_country = 'Lithuania'; break;
        case "LV": val_country = 'Latvia'; break;
        case "EE": val_country = 'Estonia'; break;
        case "AU": val_country = 'Australia'; break;
        case "NZ": val_country = 'New Zealand'; break;
        case "CY": val_country = 'Cyprus'; break;
        case "LB": val_country = 'Lebanon'; break;
        case "SA": val_country = 'Saudi Arabia'; break;
        case "AE": val_country = 'United Arab Emirates'; break;
          case "US": val_country = 'United States'; break;
        case "CA": val_country = 'Canada'; break;
        default : val_country = 'United States';
      }
      window.tc_vars.env_country = val_country
      window.tc_vars.env_language = this.$i18n.locale.toUpperCase()
      console.log("Call tC.container.reload() ")
      window.tC.container.reload()
      console.log("Call tC.event.all(this, {}) ")
      window.tC.event.all(this, {})
      console.log("END callTCReload")
    }
  }
}
</script>

<style lang="scss">
@import '~/src/assets/scss/main.scss';
</style>
<style lang="scss" scoped>

main {
  position: relative;
  padding-top: 14rem;
  padding-bottom: 1.6rem;

  &.-pop {
    padding-top: 0;
    padding-bottom: 1.6rem;
  }


  @include bp-down(md) {
    flex-grow: 1;
  }

  &.-embed {
    padding-top: 2rem;
    padding-bottom: 0;

    &.-withoutPadding {
      padding-top: 2rem;
    }
  }

  &.-withoutPadding {
    padding-top: 10rem;
  }

  .container {
    @include bp-down(md) {
      overflow: hidden;
    }
  }
}

.form {
  position: relative;
  transition: all 0.2s ease-in-out;

  &.-hide {
    opacity: 0;
    pointer-events: none;
  }
}

</style>
